import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Breathwork.css";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { AiOutlineCheck } from "react-icons/ai";
import TestimonialSldier from "../components/TestimonialSlider/TestimonialSldier";
import Testimonial from "../components/HomeComponents/Testimonial";
import Enquireform from "../components/EnquireForm/Enquireform";

function Breathwork() {
  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(
        "https://admin.oncountryconnections.com.au/api/rebirthingimage"
      );
      console.log("API response:", response.data); // Log the API response
      if (Array.isArray(response.data.urls)) {
        setImageURLs(response.data.urls);
      } else {
        console.error(
          "Image URLs are not in the expected format:",
          response.data.urls
        );
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  return (
    <>
      <section className="breathwork__section">
        <BreadCrumb title={"Rebirthing Breathwork"} />
        <div className="container" style={{ paddingTop: "8em" }}>
          <p>
            Rebirthing Breathwork is a non-invasive method of relaxed conscious
            connected breathing. It is our natural breath, the breath of life.
            The inhale is active and merges with a relaxed exhale, with no
            pausing or stopping.
          </p>
          <div className="breathwork--row">
            <div className="breathwork--image">
              {imageURLs[0] && (
                <img src={imageURLs[0]} alt="rebirthing breathwork" />
              )}
              <div className="breathwork--text breathworksecon-text">
                <p>
                  Rebirthing-Breathwork, aka Intuitive Energy Breathing or
                  Conscious Energy Breathing, is the ability to breathe Energy
                  as well as air. It is the art of learning to breathe from the
                  Breath Itself. Rebirthing is perhaps the most valuable
                  self-healing ability that humans can learn. We can not have
                  disease and relaxation in the same space at the same time.
                </p>
                <p>
                  Relaxation is the ultimate healer. Every breath induces
                  relaxation. Therefore, breathing is the basic healer.
                  Conscious Energy Breathing is the most natural healing ability
                  of all. This ability involves merging the inhale with the
                  exhale in a gentle relaxed rhythm in an intuitive way that
                  floods the body with Divine Energy.
                </p>
              </div>
            </div>

            <div className="breathwork--text">
              <h2>Rebirthing Breathwork</h2>
              <p>
                Life itself never pauses or stops and neither should our breath,
                when we align with life we are truly living. Through liberating
                the breath, we also liberate the conditioning of the mind from
                outdated beliefs and conditioning and can freely release trauma
                stored in the body.
              </p>
              <p>
                We offer one on one sessions, group sessions, workshops, events
                and retreats for individuals, communities and organisations.
                Training of new practitioners in Rebirthing Breathwork is also
                available.
              </p>
              <p>
                Contact us below to begin your Rebirthing Breathwork journey.
              </p>
              <div className="breathwork--image1">
                {imageURLs[1] && (
                  <img src={imageURLs[1]} alt="rebirthing breathwork" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Enquireform />
      <Testimonial />
    </>
  );
}

export default Breathwork;
